// @tailwind base;
// @tailwind components;
// @tailwind utilities;
// @import './colors/backgrounds.scss';
// @import './colors/borderColors.scss';
// @import './colors/textColors.scss';
// @import './components/buttons.scss';
// // @import './components/inputs.scss';
// @import './components/dropdown.scss';
// @import './components/modal.scss';
// @import './components/loaders.scss';
// @import './components/filter-dropdown.scss';
// @import './qbsTable.scss';

// * {
//   margin: 0;
// }

// //test
// :root {
//   --hue: 223;
//   --bg: hsl(var(--hue), 10%, 90%);
//   --fg: hsl(var(--hue), 10%, 10%);
//   --primary: hsl(var(--hue), 90%, 50%);
//   --trans-dur: 0.3s;
//   --trans-timing: cubic-bezier(0.76, 0.05, 0.24, 0.95);
//   --trans-timing-in: cubic-bezier(0.76, 0.05, 0.86, 0.06);
//   --trans-timing-out: cubic-bezier(0.05, 0.76, 0.06, 0.86);
//   // font-size: calc(40px + (80 - 40) * (100vw - 320px) / (2560 - 320));
// }

// body,
// input {
//   color: var(--fg);
// }
// body,
// body * {
//   // font-family: 'Nunito Sans', sans-serif;
//   font-family: 'Poppins';
// }

// .breadcrumb-navigation > li {
//   display: inline;
// }

// .breadcrumb-navigation li + li:before {
//   padding: 0 18px;
//   content: '>';
//   font-size: 15px;
// }

// .theme_switch {
//   margin: auto;
//   position: relative;
// }

// .theme_switch__icon,
// .theme_switch__input {
//   display: block;
// }

// .theme_switch__icon {
//   position: absolute;
//   top: 6px;
//   right: 4px;
//   width: 10px;
//   height: 10px;
//   transition:
//     opacity calc(var(--trans-dur) / 2),
//     transform calc(var(--trans-dur) / 2);
// }

// .theme_switch__icon polyline {
//   transition: stroke-dashoffset calc(var(--trans-dur) / 2);
// }

// .theme_switch__icon--light,
// .theme_switch__icon--light polyline {
//   transition-delay: calc(var(--trans-dur) / 2);
//   transition-timing-function: var(--trans-timing-out);
// }

// .theme_switch__icon--dark {
//   opacity: 1;
//   transform: translateX(-0.75em) rotate(30deg) scale(0.75);
//   transition-timing-function: var(--trans-timing-in);
//   right: 9px;
// }

// .theme_switch__input {
//   background-color: hsl(210, 90%, 70%);
//   border-radius: 0.75em;
//   box-shadow:
//     0 0 0 0.125em hsla(var(--hue), 90%, 50%, 0),
//     0.125em 0.125em 0.25em hsla(var(--hue), 90%, 10%, 0.2);
//   outline: transparent;
//   position: relative;
//   width: 36px;
//   height: 24px;
//   appearance: none;
//   -webkit-tap-highlight-color: transparent;
//   transition:
//     background-color var(--trans-dur) var(--trans-timing),
//     box-shadow 0.15s linear;
// }

// .theme_switch__input:focus-visible {
//   box-shadow:
//     0 0 0 0.125em hsl(var(--hue), 90%, 50%),
//     0.125em 0.125em 0.25em hsla(var(--hue), 90%, 10%, 0.2);
// }

// .theme_switch__input:before,
// .theme_switch__input:after {
//   content: '';
//   display: block;
//   position: absolute;
// }

// .theme_switch__input:before {
//   background-color: hsl(50, 90%, 50%);
//   border-radius: inherit;
//   mask-image: linear-gradient(
//     120deg,
//     hsl(0, 0%, 0%) 20%,
//     hsla(0, 0%, 0%, 0) 80%
//   );
//   -webkit-mask-image: linear-gradient(
//     120deg,
//     hsl(0, 0%, 0%) 20%,
//     hsla(0, 0%, 0%, 0) 80%
//   );
//   inset: 0;
//   transition: background-color var(--trans-dur) var(--trans-timing);
// }

// .theme_switch__input:after {
//   background-color: hsl(0, 0%, 100%);
//   border-radius: 50%;
//   box-shadow: 0.05em 0.05em 0.05em hsla(var(--hue), 90%, 10%, 0.1);
//   top: 5px;
//   left: 3px;
//   width: 14px;
//   height: 14px;
//   transition:
//     background-color var(--trans-dur) var(--trans-timing),
//     transform var(--trans-dur) var(--trans-timing);
//   z-index: 1;
// }

// .theme_switch__input:checked {
//   background-color: hsl(290, 90%, 40%);
// }

// .theme_switch__input:checked:before {
//   background-color: hsl(220, 90%, 40%);
// }

// .theme_switch__input:checked:after {
//   background-color: hsl(0, 0%, 0%);
//   transform: translateX(15px);
// }

// .theme_switch__input:checked ~ .theme_switch__icon--light,
// .theme_switch__input:checked ~ .theme_switch__icon--light polyline {
//   transition-delay: 0s;
//   transition-timing-function: var(--trans-timing-in);
// }

// .theme_switch__input:checked ~ .theme_switch__icon--light {
//   opacity: 0;
//   transform: translateX(-0.75em) rotate(-30deg) scale(0.75);
// }

// .theme_switch__input:checked ~ .theme_switch__icon--light polyline {
//   stroke-dashoffset: 1.5;
// }

// .theme_switch__input:checked ~ .switch__icon--dark {
//   opacity: 1;
//   transform: translateX(-1.5em);
//   transition-delay: calc(var(--trans-dur) / 2);
//   transition-timing-function: var(--trans-timing-out);
// }

// .theme_switch__sr {
//   overflow: hidden;
//   position: absolute;
//   width: 1px;
//   height: 1px;
// }

// .labels-text {
//   @apply text-shadow-light;
// }

// .ListItem {
//   width: 100%;
//   align-items: center;
//   box-sizing: border-box;
//   background-color: inherit;
//   height: 100%;
//   display: flex;
//   position: relative;
//   z-index: 2;
// }

// .BouncingListItem {
//   transition: transform 0.5s ease-out;
//   width: 100%;
//   align-items: center;
//   box-sizing: border-box;
//   background-color: inherit;
//   height: 100%;
//   display: flex;
//   position: relative;
//   z-index: 2;
// }

// .swipe-Background {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   z-index: 1;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   padding-right: 0;
//   box-sizing: border-box;
// }

// .swipe-Wrapper {
//   position: relative;
//   transition: max-height 0.5s ease;
//   max-height: 1000px;
//   transform-origin: top;
//   overflow: hidden;
//   width: calc(100% + 30px);
//   background: inherit;
//   margin: 0 -15px;

//   .swipe-buttons {
//     display: flex;
//     align-items: center;
//     gap: 15px;

//     // padding: 15px;
//     button {
//       background: none;
//       // border: 1px solid #fff;
//       border-radius: 4px;
//       padding: 7px 15px 7px 5px;
//       color: #10939f;
//       display: flex;
//       align-items: center;
//       gap: 5px;
//       padding: 0;
//       width: 35px;
//       height: 35px;
//       justify-content: center;

//       &:hover {
//         color: #10939f;

//         i {
//           color: #10939f;
//         }
//       }

//       &.dButton {
//         color: #e4334b;
//       }
//     }
//   }
// }

// .table-card {
//   display: flex;
//   gap: 10px;
//   padding: 2px 20px;
//   width: 100%;
//   overflow: hidden;
//   background: white;
//   position: relative;

//   @media screen and (min-width: 768px) {
//     padding: 0px;
//   }

//   &.card-border {
//     border-left: 3px solid #fff;
//     border-top: 3px solid #fff;
//     border-bottom: 3px solid #fff;

//     &.active {
//       border-left-color: #5ab921;
//     }

//     &.approved {
//       border-left-color: #3163ec;
//     }

//     &.pending {
//       border-left-color: #e6bf15;
//     }

//     &.deactivated {
//       border-left-color: #e76e0b;
//     }

//     &.inactive {
//       border-left-color: #e4334b;
//     }
//   }

//   .pic {
//     width: 40px;
//     height: 40px;
//     display: flex;
//     align-items: center;

//     div {
//       width: 30px;
//       height: 30px;
//       object-fit: cover;
//       border-radius: 100%;
//       border: 3px solid #fff;
//       margin-right: 10px;

//       &.active {
//         border-color: #5ab921;
//       }

//       &.approved {
//         border-color: #3163ec;
//       }

//       &.pending {
//         border-color: #e6bf15;
//       }

//       &.deactivated {
//         border-color: #e76e0b;
//       }

//       &.inactive {
//         border-color: #e4334b;
//       }
//     }
//   }

//   .data {
//     .type {
//       color: #fff;
//       padding: 0 5px 0 0;
//       border-radius: 0;
//       border-right: 1px solid rgba(0, 0, 0, 0.3);
//       margin-right: 5px;
//     }

//     .name {
//       b {
//         font-weight: 700;
//       }
//     }
//   }
// }

// .statusLabel {
//   color: #3a3a3a !important;
//   padding: 0px 7px;
//   border-radius: 3px;
//   margin-right: 5px;
//   // margin-bottom: 7px;
//   // font-size: 11px;
//   position: relative;
//   padding-left: 15px;

//   &:after {
//     position: absolute;
//     width: 10px;
//     height: 10px;
//     border-radius: 100%;
//     left: 0;
//     top: 3px;
//     content: '';
//   }

//   &.active {
//     &:after {
//       background: #5ab921;
//     }
//   }

//   &.inprogress {
//     &:after {
//       background: #3163ec;
//     }
//   }

//   &.pending {
//     &:after {
//       background: #c09f0c;
//     }
//   }

//   &.deactivated {
//     &:after {
//       background: #e76e0b;
//     }
//   }

//   &.inactive {
//     &:after {
//       background: #e4334b;
//     }
//   }
// }

// .table-expaned-data {
//   padding: 30px 15px;
//   padding-left: 64px;
//   flex-direction: column;

//   @media screen and (min-width: 768px) {
//     padding-left: 125px;
//     flex-direction: row;
//   }

//   .item {
//     font-weight: bold;
//     border-radius: 5px;
//     font-size: 12px;
//     padding-right: 15px;
//     color: #7a8087;

//     .value {
//       font-weight: normal;
//       color: #000000;
//     }

//     @media screen and (max-width: 767px) {
//       flex-direction: row;
//       padding: 5px;
//       border: 0;
//     }
//   }
// }

// .table-expaned-data {
//   padding: 15px;
//   display: flex;
//   gap: 15px;
//   background: #ffefe2;
//   padding-left: 50px;
//   flex-wrap: wrap;
//   justify-content: flex-start;

//   .item {
//     .value {
//       color: #000000;
//     }
//   }
// }

// .hasStickyAction {
//   .rdt_TableHead {
//     .rdt_TableCol:last-child {
//       position: sticky;
//       right: 0;
//       z-index: 0;
//       padding-right: 0;
//       background: #fff;
//     }
//   }

//   .rdt_TableBody {
//     .rdt_TableCell:last-child {
//       padding-right: 0;

//       .actionColumn {
//         padding-right: 16px;
//         z-index: 1;
//       }
//     }
//   }
// }

// .actionColumn {
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
//   // background: #fff;
// }

// .table-loader {
//   overflow: hidden;
// }

// .table-loader:before {
//   visibility: visible;
//   display: table-caption;
//   content: ' ';
//   width: 2500px;
//   height: 600px;
//   background-image: linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 0),
//     linear-gradient(90deg, #000 0, transparent 0),
//     linear-gradient(
//       90deg,
//       rgb(0 0 0 / 0%),
//       rgba(0, 0, 0, 0.1) 20%,
//       rgba(0, 0, 0, 0) 30%
//     ),
//     linear-gradient(#10939f05 55px, transparent 0);
//   background-repeat: repeat;
//   background-size:
//     1px 55px,
//     calc(100% * 0.1666666666) 1px,
//     30% 100%,
//     2px 110px;
//   background-position:
//     0 0,
//     0 0,
//     0 0,
//     0 0;
//   animation: shine 0.5s infinite;
// }

// @keyframes shine {
//   to {
//     background-position:
//       0 0,
//       0 0,
//       40% 0,
//       0 0;
//   }
// }

// .side-drawer {
//   height: 100%;
//   background: white;
//   position: fixed;
//   top: 0;
//   right: 0;
//   width: 300px;
//   z-index: 200;
//   box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
//   transform: translateX(100%);
//   transition: transform 0.3s ease-out;
// }

// .side-drawer.open {
//   transform: translateX(0);
// }

// .backdrop {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 100;
//   top: 0;
//   right: 0;
// }

// .notifications-container {
//   width: 300px;
//   margin: 0 auto;
//   padding: 20px;
//   right: 0;
//   position: absolute;
//   border-radius: 5px;
//   z-index: 999;
// }

// .notifications-list {
//   list-style: none;
//   padding: 0;
// }

// .notification-item {
//   display: flex;
//   align-items: center;
//   margin-bottom: 10px;
//   padding: 10px;
//   border: 1px solid #e0e0e0;
//   justify-content: space-between;
//   border-radius: 5px;
//   background-color: #f8f8f8;
// }

// .dismiss-button {
//   margin-left: 10px;
//   border: none;
//   border-radius: 3px;
//   padding: 5px 10px;
//   cursor: pointer;
// }

// .dismiss-button:hover {
//   background-color: #c0392b;
// }

// //// dark
// .dark {
//   .table-card {
//     background: #424242;

//     &.card-border {
//       border-left: 3px solid #424242;
//       border-top: 3px solid #424242;
//       border-bottom: 3px solid #424242;
//     }

//     .pic {
//       div {
//         border: 3px solid #424242;
//       }
//     }

//     .data {
//       .type {
//         color: #424242;
//         border-right: 1px solid #fff;
//       }
//     }
//   }

//   .statusLabel {
//     color: #fff !important;
//   }

//   .table-expaned-data {
//     .item {
//       color: #7a8087;

//       .value {
//         color: #fff;
//       }
//     }
//   }

//   .table-expaned-data {
//     background: #353232;

//     .item {
//       .value {
//         color: #fff;
//       }
//     }
//   }

//   .actionColumn {
//     background: #424242;
//   }

//   .hasStickyAction {
//     .rdt_TableHead {
//       .rdt_TableCol:last-child {
//         background: #424242 !important;
//       }
//     }
//   }

//   .table-loader:before {
//     background-image: linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 0),
//       linear-gradient(90deg, #000 0, transparent 0),
//       linear-gradient(
//         90deg,
//         rgb(0 0 0 / 0%),
//         rgba(0, 0, 0, 0.1) 20%,
//         rgba(0, 0, 0, 0) 30%
//       ),
//       linear-gradient(#10939f05 55px, transparent 0);
//   }

//   .side-drawer {
//     background: white;
//     box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
//   }

//   .backdrop {
//     background-color: #424242;
//   }

//   .notification-item {
//     border: 1px solid #424242;
//     background-color: #fff;
//   }

//   // rdt_Table rdt_TableRow rdt_TableCol rdt_TableCol_Sortable rdt_TableCell rdt_TableHeader rdt_TableFooter rdt_TableHead rdt_TableHeadRow rdt_TableBody rdt_ExpanderRow
// }

// .tab-scroll::-webkit-scrollbar {
//   width: 1px;
//   height: 1px;
//   background: #424242;
//   /* make scrollbar transparent */
// }

// div.status-polygon {
//   display: flex;
//   width: auto;
//   height: 18px;
//   padding-right: 5px;
//   padding-left: 5px;
//   background: #eef0fa;
//   align-items: center;
//   justify-content: center;
//   font-size: 10px;
//   // outline: 1px solid #273EC3;
//   box-sizing: content-box;
//   border-bottom: 1px solid #273ec3;
//   color: 1px solid #273ec3;
//   border-top: 1px solid #273ec3;
// }

// .MuiAvatar-circular.MuiAvatar-colorDefault {
//   font-size: 12px;
// }

// .customScroll::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
//   border-radius: 0px;
//   background-color: transparent;
// }

// .customScroll::-webkit-scrollbar {
//   width: 0px;
//   height: 4px;
//   background-color: transparent;
// }

// .customScroll::-webkit-scrollbar-thumb {
//   border-radius: 100px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
//   background-color: #ddd;
// }

// .iconActive {
//   svg * {
//     stroke: #45b755;
//   }
// }
.css-qbdosj-Input input {
  z-index: 999 !important;
}
.css-1nmdiq5-menu {
  z-index: 999 !important;
}
.table-header-input input:focus {
  border: none;
  outline: none;
}
.table-header-select {
  width: 200px;
}
.table-header-select select {
  border-radius: 10px;
  outline: none;
}
.table-header-select select option {
  border: 1px solid red;
  border-radius: 10px;
}

// .dropIconActive {
//   svg * {
//     stroke: #45B755 !important;
//   }
// }

// span.iconBox.iconBox-active {
//   color: #45B755;
//   border: 1px solid #45B755;
//   transition: all 0.2s ease;
// }

// span.iconBox {
//   width: 20px;
//   height: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 1px solid #fff;
//   border-radius: 4px;
//   transition: all 0.2s ease;
//   color: #fff;
//   flex: 0 0 20px;
//   font-size: 12px;
// }

// span.iconBox.iconBox_bg-active {
//   color: #fff;
//   border: 1px solid #fff;
// }

// .darkIcon {
//   svg * {
//     stroke: #222;
//   }
// }

// .iconSml svg {
//   width: 20px;
//   height: 20px;
// }
// .textfield {
//   border: 1px solid #636363 !important;
//   color: #636363;
//   border-radius: 5px;
//   min-height: 46px;
//   max-height: 46px;
//   padding: 8px;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 24px;
//   transition: all 0.2s ease;

//   &:focus {
//     outline: none;
//     border: 1px solid #45B755 !important;
//     transition: all 0.2s ease;
//   }
// }

// .textfield::placeholder {
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
// }

// .react-datepicker-popper {
//   z-index: 99 !important;
// }

// .iconBlue {
//   svg * {
//     stroke: #336cfc;
//   }
// }

// .iconWhite {
//   svg * {
//     stroke: #fff;
//   }
// }
// .iconBlack {
//   svg * {
//     stroke: #222;
//   }
// }

// .iconGrey {
//   svg * {
//     stroke: #999696;
//   }
// }

// .iconPrimary {
//   svg * {
//     stroke: #45B755;
//   }
// }

// .iconSize-base {
//   svg {
//     width: 16px;
//   }
// }

// .labels {
//   @apply font-semibold text-secondary mb-1.5 block;
//   line-height: 24px;
//   font-size: 14px !important;
//   color: #636363;
//   font-weight: 500;
// }

// .react-datepicker-wrapper {
//   width: 100%;
// }

// // .qbs-table-qbs-table-menu-dropdown-content {
// //   right: 10px !important;
// //   padding-left: 12px !important;
// //   min-width: auto !important;
// // }

// .formDrawer {
//   width: 400px;
// }

// .customDrawer > .MuiDrawer-paper {
//   right: 8px;
//   top: 9px;
//   height: calc(100% - 16px);
//   box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
//   border-radius: 12px;
// }

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
//   border-radius: 0px;
//   background-color: rgba(45, 45, 45, 0.05);
// }

// ::-webkit-scrollbar {
//   width: 6px;
//   height: 4px;
//   background-color: transparent;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 100px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
//   background-color: rgba(45, 45, 45, 0.5);
// }

// .menuIconWidth svg {
//   width: 18px;
//   height: 18px;
// }

// .tileIconWidth svg {
//   width: 18px;
//   height: 18px;
// }

// .tileIconWidth-xs svg {
//   width: 16px !important;
//   height: 16px !important;
// }

// // .qbs-table-toolbar-sub-container-start {
// //   gap: 12px !important;
// //   .selected-row {
// //     font-size: 14px;
// //     font-style: normal;
// //     font-weight: 500;
// //     line-height: 24px;
// //     color: #222;
// //   }
// //   .selected-row-action {
// //     gap: 8px !important;
// //     .btn {
// //       padding: 4px 8px;
// //       height: 24px;
// //       font-size: 12px;
// //       font-style: normal;
// //       font-weight: 600;
// //       line-height: 16px;
// //       border-radius: 4px;
// //       border: 1px solid #45B755;
// //       background: #fff;
// //     }
// //   }
// // }

// button.btn.relative.btn-secondary {
//   border: 1px solid #999696 !important;
//   div {
//     color: #222 !important;
//   }
// }

// input.authCheckbox {
//   transition: all 0.2s ease;
//   border-radius: 3px;
//   height: 22px;
//   width: 22px;
// }
// input.authCheckbox:checked {
//   transition: all 0.2s ease;
//   border-radius: 3px;
//   background: #45b755;
//   border-color: #45b755;
//   accent-color: #45b755;
// }

// .custom-checkbox {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .custom-checkbox .custom-checkbox-input {
//   opacity: 0;
// }
// .custom-checkbox .custom-checkbox-input ~ label {
//   position: absolute;
//   border-radius: 3px;
//   border: 1px solid #999696;
//   width: 16px;
//   height: 16px;
//   top: 3px;
//   transition: all 0.2s ease;
//   cursor: pointer;
// }
// .custom-checkbox .custom-checkbox-input ~ label svg {
//   opacity: 0;
//   transition: all 0.2s ease;
//   position: absolute;
//   left: 3px;
//   top: 3px;
//   width: 9px;
//   height: 9px;
// }
// .custom-checkbox .custom-checkbox-input:checked ~ label {
//   transition: all 0.2s ease;
//   border-radius: 3px;
//   background: #45B755;
//   border-color: #45B755;
// }
// .custom-checkbox .custom-checkbox-input:checked ~ label svg {
//   opacity: 1;
//   transition: all 0.2s ease;
// }
// .duplicate-dropdown-list {
//   position: absolute;
//   width: 100%;
//   min-height: auto;
//   max-height: 250px;
//   overflow-y: auto;
//   padding: 8px;
//   border-radius: 8px;
//   background: #fff;
//   box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
//   z-index: 999;
//   margin-top: 8px;
//   display: flex;
//   flex-direction: column;
//   gap: 4px;
// }

// .duplicate-dropdown-list li {
//   padding: 8px;
//   cursor: pointer;
//   display: flex;
//   flex-direction: column;
//   gap: 2px;
//   border-radius: 6px;
// }
// .duplicate-dropdown-list li > div:first-child span {
//   font-size: 14px !important;
//   font-style: normal !important;
//   font-weight: 600 !important;
//   line-height: 20px !important;
// }

// .duplicate-dropdown-list li:last-child {
//   border-bottom: none;
// }

// .duplicate-dropdown-list li:hover {
//   background-color: #f0f0f0;
// }

// .duplicate-dropdown-list li:first-child {
//   font-weight: 600;
// }

// .duplicate-dropdown-list li .fieldNameStyle {
//   color: #e97575 !important;
//   font-size: 14px !important;
//   font-style: normal !important;
//   font-weight: 600 !important;
//   line-height: 20px !important;
// }

// .iconWidthSm svg {
//   width: 16px !important;
//   height: 16px !important;
// }
// // .qbs-table-custom-pagination .rows-count {
// //   font-size: 14px;
// //   font-style: normal;
// //   font-weight: 500;
// //   line-height: 20px;
// //   color: #313131;
// // }
// .custom-checkbox {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .custom-checkbox input {
//   opacity: 0;
// }

// .custom-checkbox input ~ label {
//   position: absolute;
//   border-radius: 3px;
//   border: 1px solid #999696;
//   width: 16px;
//   height: 16px;
//   top: 3px;
//   transition: all 0.2s ease;
//   cursor: pointer;
// }

// .custom-checkbox input ~ label svg {
//   opacity: 0;
//   transition: all 0.2s ease;
//   position: absolute;
//   left: 3px;
//   top: 3px;
//   width: 8px;
//   height: 8px;
// }

// .custom-checkbox input:checked ~ label {
//   transition: all 0.2s ease;
//   border-radius: 3px;
//   background: #45B755;
//   border-color: #45B755;
// }
// .custom-checkbox input:checked ~ label svg {
//   opacity: 1;
//   transition: all 0.2s ease;
// }

// .custom-checkbox-container {
//   padding: 6px 8px;
//   white-space: nowrap;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   gap: 12px;
// }

// .custom-checkbox-container .custom-checkbox-value {
//   flex: 1;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
// }

// .custom-checkbox-container .custom-checkbox {
//   top: -5px;
// }

// .managedList-tabs {
//   padding: 4px;
//   border-radius: 6px;
//   background: #edeff1;
// }

// .managedList-tabs button.MuiButtonBase-root.MuiTab-root {
//   padding: 4px 8px;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 16px;
//   text-transform: capitalize;
//   height: 24px;
//   min-height: 24px;
//   min-width: auto !important;
//   color: #585858;
//   border-radius: 4px;
//   // font-family: 'Nunito Sans', sans-serif !important;
//   font-family: 'Poppins' !important;
// }

// .managedList-tabs > .MuiTabs-root {
//   min-height: auto;
// }

// .managedList-tabs span.MuiTabs-indicator {
//   background: transparent;
// }

// .managedList-tabs button.MuiButtonBase-root.MuiTab-root.Mui-selected {
//   background: #fff;
//   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
// }

// .managedList-tabs
//   button.MuiButtonBase-root.MuiTab-root
//   span.MuiTouchRipple-root {
//   display: none;
// }

// .customMenu ul.MuiMenu-list {
//   padding: 8px;
// }

// .customMenu .MuiPopover-paper.MuiMenu-paper {
//   margin-top: 18px;
//   min-width: 180px;
//   border-radius: 8px;
//   background: #fff;
//   box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
// }

// .customMenu ul.MuiMenu-list li {
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   padding: 8px;
//   border-radius: 6px;
// }

// .iconWidthXs svg {
//   width: 15px !important;
//   height: 15px !important;
// }

// .customAccordion {
//   box-shadow: none !important;
//   background: #fff;
//   border-radius: 0 !important;
//   margin: 0 !important;
// }
// .customAccordion .accordionHeader {
//   display: flex;
//   align-items: center;
//   flex: 1;
//   gap: 4px;
//   min-height: auto !important;
//   padding: 0;
// }

// .customAccordion .accordionHeader .MuiAccordionSummary-content,
// .customAccordion .accordionHeader .MuiAccordionSummary-content p {
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 24px;
//   margin: 0 !important;
//   // font-family: 'Nunito Sans', sans-serif;
//   font-family: 'Poppins';
// }
// .customAccordion .accordionDetails.accordionHidden {
//   overflow: hidden;
//   max-height: 0;
//   transition: all 0.35s ease-out;
//   padding: 0 20px;
// }
// .customAccordion .accordionDetails {
//   padding: 0 20px 16px;
//   max-height: 400px;
//   transition: all 0.35s ease-out;
//   overflow-y: auto;
// }

// .customFieldTable {
//   border: 1px solid #d6d8dc;
//   border-radius: 4px;
//   background: #fff;
// }

// .customFieldTable > .customFieldTable-container {
//   box-shadow: none;
//   border: none;
// }

// .customFieldTable table thead tr th {
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 16px;
//   padding: 10px 8px;
//   background: #f2f2f2;
// }

// .customFieldTable table tbody tr th,
// .customFieldTable table tbody tr td {
//   padding: 8px;
//   line-height: normal !important;
//   background: #f8f8f8;
//   vertical-align: baseline;
// }

// .customFieldTable table tbody tr th .textfield,
// .customFieldTable table tbody tr td .textfield,
// .customFieldTable table tbody tr td .textfield-error,
// .customFieldTable table tbody tr th .textfield-error {
//   min-height: 24px;
//   max-height: 24px;
//   padding: 0 8px !important;
// }

// // .customFieldTable table tbody tr th .qbs-autocomplete-close-icon {
// //   top: 5px;
// // }
// // .rs-table-scrollbar-horizontal {
// //   display: none !important;
// // }
// .rs-table-cell-header .rs-table-cell-content {
//   vertical-align: middle !important;
// }
// .customFieldTable table tbody tr td.customFieldTable-cell,
// .customFieldTable table tbody tr td.actionCell {
//   background: #fff;
//   vertical-align: middle;
// }
// .rs-table-cell-last {
//   display: none !important;
// }
// .rs-table-cell-group-fixed-right {
//   display: none !important;
// }
// .iconRotate svg {
//   transform: rotate(180deg);
//   transition: all 0.2s ease;
// }
// .customAccordion .accordionHeader span.flex-1 {
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 24px;
//   color: #313131;
// }

// .customFieldTable table tbody tr td .textfield {
//   text-align: right;
// }

// .customFileUpload .customFileUpload-field > input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   z-index: -3;
// }
// .menuopened {
//   position: absolute;
//   left: 0;
//   top: 100%;
//   margin-top: 4px;
//   border-radius: 8px;
//   background: #fff;
//   box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
//   padding: 8px;
//   display: flex;
//   flex-direction: column;
//   gap: 4px;
//   min-width: 220px;
//   z-index: 9;
// }

// .menuopened .menuopened-item {
//   padding: 6px 8px;
//   border-radius: 6px;
//   cursor: pointer;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   text-align: left;
//   color: #222;
// }
// .menuopened .menuopened-item:hover {
//   background: #f8f8f8;
// }

// .customRadioButton .customRadio-field > div {
//   position: relative;
// }

// .customRadioButton .customRadio-field input {
//   opacity: 0;
//   position: absolute;
// }

// .customRadioButton .customRadio-field input ~ label {
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   color: #222;
//   padding-left: 22px;
// }

// .customRadioButton .customRadio-field input ~ label:before {
//   content: '';
//   width: 16px;
//   height: 16px;
//   position: absolute;
//   left: 0;
//   border-radius: 100px;
//   border: 1px solid #d6d8dc;
//   background: #fff;
//   top: 2px;
//   transition: all 0.2s ease;
// }

// .customRadioButton .customRadio-field input:checked ~ label:before {
//   border: 1px solid #45B755;
//   transition: all 0.2s ease;
// }
// .bg-mainBgColor .mx-9 {
//   border: 0 !important;
// }
// .bg-mainBgColor .mx-9 {
//   border: 0 !important;
// }
// .rs-table-cell-content {
//   padding-left: 15px !important;
// }

// .customRadioButton .customRadio-field input ~ label:after {
//   content: '';
//   position: absolute;
//   left: 3px;
//   top: 5px;
//   width: 10px;
//   height: 10px;
//   background: #45B755;
//   border-radius: 100px;
//   opacity: 0;
//   transition: all 0.2s ease;
// }

// .customRadioButton .customRadio-field input:checked ~ label:after {
//   opacity: 1;
//   transition: all 0.2s ease;
// }
// textarea.textfield,
// textarea.textfield-error {
//   height: auto !important;
//   min-height: initial !important;
//   max-height: initial !important;
//   border-radius: 6px;
//   padding: 8px;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   transition: all 0.2s ease;
// }
// textarea.textfield-error::placeholder {
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
// }
// .commentFormField {
//   position: relative;
// }

// .commentFormField > button {
//   position: absolute;
//   right: 0;
//   top: 0;
//   height: 100%;
//   width: 36px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 1;
// }

// ul.notesList {
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// }

// ul.notesList ul.commentList {
//   margin-top: 20px;
//   padding-left: 60px;
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
// }

// ul.notesList ul.commentList ul.commentList {
//   margin-top: 16px;
//   gap: 12px;
// }

// .arrow-steps .step {
//   font-size: 14px;
//   text-align: center;
//   color: #666;
//   cursor: default;
//   margin: 0 3px 0 0;
//   padding: 10px 10px 10px 10px;
//   min-width: 180px;
//   height: 40px;
//   float: left;
//   position: relative;
//   background-color: #f2f2f2;
//   -webkit-user-select: none;
//   user-select: none;
//   transition: background-color 0.2s ease;
//   flex: 1;
// }

// .arrow-steps .step:after,
// .arrow-steps .step:before {
//   content: ' ';
//   content: ' ';
//   position: absolute;
//   top: 0;
//   right: -18px;
//   width: 0;
//   height: 40px;
//   border-top: 19px solid transparent;
//   border-bottom: 20px solid transparent;
//   border-left: 19px solid #f2f2f2;
//   z-index: 2;
//   transition: border-color 0.2s ease;
// }

// .arrow-steps .step:before {
//   right: auto;
//   left: 0px;
//   border-left: 20px solid #fff;
//   z-index: 0;
// }

// .arrow-steps .step:first-child:before {
//   border: none;
// }

// .arrow-steps .step:first-child {
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
// }

// .arrow-steps .step span {
//   position: relative;
// }

// .arrow-steps .step span:before {
//   opacity: 0;
//   content: '✔';
//   position: absolute;
//   top: -2px;
//   left: -20px;
// }

// .arrow-steps .step.done span:before {
//   opacity: 1;
//   -webkit-transition: opacity 0.3s ease 0.5s;
//   -moz-transition: opacity 0.3s ease 0.5s;
//   -ms-transition: opacity 0.3s ease 0.5s;
//   transition: opacity 0.3s ease 0.5s;
// }

// .arrow-steps .step.current {
//   color: #299957;
//   background-color: #d4ebdd;
// }

// .arrow-steps .step.lost {
//   color: #b6514c;
//   background-color: #cf8787;
// }
// .arrow-steps .step.lost:after {
//   border-left: 19px solid #cf8787;
// }
// .arrow-steps .step.current:after {
//   border-left: 19px solid #d4ebdd;
// }
// .arrow-steps {
//   margin-bottom: 10px;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
// .arrow-steps .step:last-child:after {
//   border: none !important;
// }
// .menuopened.styledMenu {
//   min-width: 100%;
//   margin-top: 20px;
// }
// // .qbs-autocomplete-suggestions {
// //   z-index: 999 !important;
// // }
// // .qbs-table .qbs-table-search-container > form {
// //   position: relative;
// // }
// // .action_label {
// //   cursor: pointer;
// //   color: #4c7ad6;
// //   font-size: 13px !important;
// //   font-style: normal;
// //   font-weight: 700;
// //   line-height: 16px;
// //   margin-bottom: 0.375rem;
// //   position: relative;
// // }
// // .action_label:before {
// //   content: " + ";
// //   position: absolute;
// //   left: -16px;
// //   top: -1px;
// //   color: #4c7ad6;
// //   font-size: 18px;
// //   font-style: normal;
// //   font-weight: 700;
// //   line-height: 16px;
// // }
// .autoWidth > div {
//   margin-bottom: 0 !important;
// }

// // .qbs-table .qbs-table-search-container .input {
// //   padding-left: 30px !important;
// //   padding-right: 32px !important;
// // }
// // .qbs-table .qbs-table-search-container .close-button {
// //   right: 12px !important;
// // }

// .textfield:disabled,
// .textfield-big:disabled {
//   border-radius: 4px;
//   border: #d6d8dc;
//   background: #f2f2f2;
// }

// // .qbs-table .qbs-table-toolbar-container {
// //   gap: unset !important;
// // }

// // .qbs-table .qbs-table-toolbar-container .qbs-table-toolbar {
// //   margin-bottom: 12px !important;
// // }

// // .qbs-table
// //   .qbs-table-toolbar-container
// //   .sub-qbs-table-toolbar
// //   .flex.items-center.flex-wrap
// //   > div {
// //   margin-bottom: 12px !important;
// // }

// // .customDrawer
// //   .formDrawer
// //   .flex.flex-col
// //   > div:nth-last-child(2)
// //   .qbs-autocomplete-suggestions {
// //   bottom: 100%;
// // }
// // .customDrawer
// //   .formDrawer
// //   .flex.flex-col
// //   > div:nth-last-child(3)
// //   .qbs-autocomplete-suggestions {
// //   bottom: 100%;
// // }

// // .customDrawer
// //   .formDrawer
// //   .flex.flex-col
// //   > div:last-child
// //   .qbs-autocomplete-suggestions {
// //   bottom: 100%;
// // }

// // .customFieldTable tbody tr:last-child th .qbs-autocomplete-suggestions {
// //   bottom: 100%;
// // }
// // .customFieldTable tbody tr:nth-last-child(2) th .qbs-autocomplete-suggestions {
// //   bottom: 100%;
// // }
// // .customFieldTable tbody tr:nth-last-child(3) th .qbs-autocomplete-suggestions {
// //   bottom: 100%;
// // }

// // .customFieldTable tbody tr:nth-last-child(4) th .qbs-autocomplete-suggestions {
// //   bottom: 100%;
// // }
// // .customFieldTable tbody tr:first-child th .qbs-autocomplete-suggestions {
// //   bottom: unset !important;
// // }
// // .customFieldTable tbody tr:nth-child(2) th .qbs-autocomplete-suggestions,
// // .customFieldTable tbody tr:nth-child(3) th .qbs-autocomplete-suggestions,
// // .customFieldTable tbody tr:nth-child(4) th .qbs-autocomplete-suggestions {
// //   bottom: unset !important;
// // }
// // .rs-table {
// //   overflow-x: auto !important;
// // }
// // .rs-table-header-row-wrapper {
// //   overflow-x: auto !important;
// // }
// // .rs-table-row {
// //   overflow-x: auto !important;
// // }
// .rs-table-row-header .rs-table-cell {
//   font-size: 19px !important;
// }
// .rs-table-body-row-wrapper .rs-table-cell-first {
//   text-transform: capitalize !important;
// }
// .rs-table-body-row-wrapper .rs-table-cell-content {
//   font-weight: 400 !important;
//   font-size: 16px !important;
// }

// .qbs-autocomplete-close-icon {
//   display: none !important;
// }

// .rs-table-cell-header .rs-table-cell-content {
//   font-size: 19px !important;
// }

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './colors/backgrounds.scss';
@import './colors/borderColors.scss';
@import './colors/textColors.scss';
@import './components/buttons.scss';
// @import './components/inputs.scss';
@import './components/dropdown.scss';
@import './components/modal.scss';
@import './components/loaders.scss';
@import './components/filter-dropdown.scss';
@import './dark.scss';

* {
  margin: 0;
}

//test
:root {
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --primary: hsl(var(--hue), 90%, 50%);
  --trans-dur: 0.3s;
  --trans-timing: cubic-bezier(0.76, 0.05, 0.24, 0.95);
  --trans-timing-in: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  --trans-timing-out: cubic-bezier(0.05, 0.76, 0.06, 0.86);
  // font-size: calc(40px + (80 - 40) * (100vw - 320px) / (2560 - 320));
}

body,
input {
  color: var(--fg);
}
body,
body * {
  font-family: 'Nunito Sans', sans-serif;
}

.breadcrumb-navigation > li {
  display: inline;
}

.breadcrumb-navigation li + li:before {
  padding: 0 4px;
  content: '-';
  font-size: 15px;
}

.theme_switch {
  margin: auto;
  position: relative;
}

.theme_switch__icon,
.theme_switch__input {
  display: block;
}

.theme_switch__icon {
  position: absolute;
  top: 6px;
  right: 4px;
  width: 10px;
  height: 10px;
  transition:
    opacity calc(var(--trans-dur) / 2),
    transform calc(var(--trans-dur) / 2);
}

.theme_switch__icon polyline {
  transition: stroke-dashoffset calc(var(--trans-dur) / 2);
}

.theme_switch__icon--light,
.theme_switch__icon--light polyline {
  transition-delay: calc(var(--trans-dur) / 2);
  transition-timing-function: var(--trans-timing-out);
}

.theme_switch__icon--dark {
  opacity: 1;
  transform: translateX(-0.75em) rotate(30deg) scale(0.75);
  transition-timing-function: var(--trans-timing-in);
  right: 9px;
}

.theme_switch__input {
  background-color: hsl(210, 90%, 70%);
  border-radius: 0.75em;
  box-shadow:
    0 0 0 0.125em hsla(var(--hue), 90%, 50%, 0),
    0.125em 0.125em 0.25em hsla(var(--hue), 90%, 10%, 0.2);
  outline: transparent;
  position: relative;
  width: 36px;
  height: 24px;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition:
    background-color var(--trans-dur) var(--trans-timing),
    box-shadow 0.15s linear;
}

.theme_switch__input:focus-visible {
  box-shadow:
    0 0 0 0.125em hsl(var(--hue), 90%, 50%),
    0.125em 0.125em 0.25em hsla(var(--hue), 90%, 10%, 0.2);
}
.rs-table-cell-first {
  text-transform: capitalize !important;
}
// .rs-table-cell-first .rs-table-cell-content {
//   font-weight: 500 !important;
// }
.theme_switch__input:before,
.theme_switch__input:after {
  content: '';
  display: block;
  position: absolute;
}

.theme_switch__input:before {
  background-color: hsl(50, 90%, 50%);
  border-radius: inherit;
  mask-image: linear-gradient(
    120deg,
    hsl(0, 0%, 0%) 20%,
    hsla(0, 0%, 0%, 0) 80%
  );
  -webkit-mask-image: linear-gradient(
    120deg,
    hsl(0, 0%, 0%) 20%,
    hsla(0, 0%, 0%, 0) 80%
  );
  inset: 0;
  transition: background-color var(--trans-dur) var(--trans-timing);
}

.theme_switch__input:after {
  background-color: hsl(0, 0%, 100%);
  border-radius: 50%;
  box-shadow: 0.05em 0.05em 0.05em hsla(var(--hue), 90%, 10%, 0.1);
  top: 5px;
  left: 3px;
  width: 14px;
  height: 14px;
  transition:
    background-color var(--trans-dur) var(--trans-timing),
    transform var(--trans-dur) var(--trans-timing);
  z-index: 1;
}

.theme_switch__input:checked {
  background-color: hsl(290, 90%, 40%);
}

.theme_switch__input:checked:before {
  background-color: hsl(220, 90%, 40%);
}

.theme_switch__input:checked:after {
  background-color: hsl(0, 0%, 0%);
  transform: translateX(15px);
}

.theme_switch__input:checked ~ .theme_switch__icon--light,
.theme_switch__input:checked ~ .theme_switch__icon--light polyline {
  transition-delay: 0s;
  transition-timing-function: var(--trans-timing-in);
}

.theme_switch__input:checked ~ .theme_switch__icon--light {
  opacity: 0;
  transform: translateX(-0.75em) rotate(-30deg) scale(0.75);
}

.theme_switch__input:checked ~ .theme_switch__icon--light polyline {
  stroke-dashoffset: 1.5;
}

.theme_switch__input:checked ~ .switch__icon--dark {
  opacity: 1;
  transform: translateX(-1.5em);
  transition-delay: calc(var(--trans-dur) / 2);
  transition-timing-function: var(--trans-timing-out);
}

.theme_switch__sr {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.labels-text {
  @apply text-shadow-light dark:text-white;
}

.ListItem {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: inherit;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 2;
}

.BouncingListItem {
  transition: transform 0.5s ease-out;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: inherit;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 2;
}

.swipe-Background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0;
  box-sizing: border-box;
}

.swipe-Wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: calc(100% + 30px);
  background: inherit;
  margin: 0 -15px;

  .swipe-buttons {
    display: flex;
    align-items: center;
    gap: 15px;

    // padding: 15px;
    button {
      background: none;
      // border: 1px solid #fff;
      border-radius: 4px;
      padding: 7px 15px 7px 5px;
      color: #10939f;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 0;
      width: 35px;
      height: 35px;
      justify-content: center;

      &:hover {
        color: #10939f;

        i {
          color: #10939f;
        }
      }

      &.dButton {
        color: #e4334b;
      }
    }
  }
}

.table-card {
  display: flex;
  gap: 10px;
  padding: 2px 20px;
  width: 100%;
  overflow: hidden;
  background: white;
  position: relative;

  @media screen and (min-width: 768px) {
    padding: 0px;
  }

  &.card-border {
    border-left: 3px solid #fff;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;

    &.active {
      border-left-color: #5ab921;
    }

    &.approved {
      border-left-color: #3163ec;
    }

    &.pending {
      border-left-color: #e6bf15;
    }

    &.deactivated {
      border-left-color: #e76e0b;
    }

    &.inactive {
      border-left-color: #e4334b;
    }
  }

  .pic {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;

    div {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 100%;
      border: 3px solid #fff;
      margin-right: 10px;

      &.active {
        border-color: #5ab921;
      }

      &.approved {
        border-color: #3163ec;
      }

      &.pending {
        border-color: #e6bf15;
      }

      &.deactivated {
        border-color: #e76e0b;
      }

      &.inactive {
        border-color: #e4334b;
      }
    }
  }

  .data {
    .type {
      color: #fff;
      padding: 0 5px 0 0;
      border-radius: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      margin-right: 5px;
    }

    .name {
      b {
        font-weight: 700;
      }
    }
  }
}

/* .statusLabel {
  color: #3a3a3a !important;
  padding: 0px 7px;
  border-radius: 3px;
  margin-right: 5px;
  // margin-bottom: 7px;
  // font-size: 11px;
  position: relative;
  padding-left: 15px;

  &:after {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    left: 0;
    top: 3px;
    content: '';
  }

  &.active {
    &:after {
      background: #5ab921;
    }
  }

  &.inprogress {
    &:after {
      background: #3163ec;
    }
  }

  &.pending {
    &:after {
      background: #c09f0c;
    }
  }

  &.deactivated {
    &:after {
      background: #e76e0b;
    }
  }

  &.inactive {
    &:after {
      background: #e4334b;
    }
  }
} */

.table-expaned-data {
  padding: 30px 15px;
  padding-left: 64px;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    padding-left: 125px;
    flex-direction: row;
  }

  .item {
    font-weight: bold;
    border-radius: 5px;
    font-size: 12px;
    padding-right: 15px;
    color: #7a8087;

    .value {
      font-weight: normal;
      color: #000000;
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      padding: 5px;
      border: 0;
    }
  }
}

.table-expaned-data {
  padding: 15px;
  display: flex;
  gap: 15px;
  background: #ffefe2;
  padding-left: 50px;
  flex-wrap: wrap;
  justify-content: flex-start;

  .item {
    .value {
      color: #000000;
    }
  }
}

.hasStickyAction {
  .rdt_TableHead {
    .rdt_TableCol:last-child {
      position: sticky;
      right: 0;
      z-index: 0;
      padding-right: 0;
      background: #fff;
    }
  }

  .rdt_TableBody {
    .rdt_TableCell:last-child {
      padding-right: 0;

      .actionColumn {
        padding-right: 16px;
        z-index: 1;
      }
    }
  }
}

.actionColumn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  // background: #fff;
}

.table-loader {
  overflow: hidden;
}

.table-loader:before {
  visibility: visible;
  display: table-caption;
  content: ' ';
  width: 2500px;
  height: 600px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 0),
    linear-gradient(90deg, #000 0, transparent 0),
    linear-gradient(
      90deg,
      rgb(0 0 0 / 0%),
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 0) 30%
    ),
    linear-gradient(#10939f05 55px, transparent 0);
  background-repeat: repeat;
  background-size:
    1px 55px,
    calc(100% * 0.1666666666) 1px,
    30% 100%,
    2px 110px;
  background-position:
    0 0,
    0 0,
    0 0,
    0 0;
  animation: shine 0.5s infinite;
}

@keyframes shine {
  to {
    background-position:
      0 0,
      0 0,
      40% 0,
      0 0;
  }
}

.side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
}

.notifications-container {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  right: 0;
  position: absolute;
  border-radius: 5px;
  z-index: 999;
}

.notifications-list {
  list-style: none;
  padding: 0;
}

.notification-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #f8f8f8;
}

.dismiss-button {
  margin-left: 10px;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.dismiss-button:hover {
  background-color: #c0392b;
}

//// dark
.dark {
  .table-card {
    background: #424242;

    &.card-border {
      border-left: 3px solid #424242;
      border-top: 3px solid #424242;
      border-bottom: 3px solid #424242;
    }

    .pic {
      div {
        border: 3px solid #424242;
      }
    }

    .data {
      .type {
        color: #424242;
        border-right: 1px solid #fff;
      }
    }
  }

  .statusLabel {
    color: #fff !important;
  }

  .table-expaned-data {
    .item {
      color: #7a8087;

      .value {
        color: #fff;
      }
    }
  }

  .table-expaned-data {
    background: #353232;

    .item {
      .value {
        color: #fff;
      }
    }
  }

  .actionColumn {
    background: #424242;
  }

  .hasStickyAction {
    .rdt_TableHead {
      .rdt_TableCol:last-child {
        background: #424242 !important;
      }
    }
  }

  .table-loader:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 0),
      linear-gradient(90deg, #000 0, transparent 0),
      linear-gradient(
        90deg,
        rgb(0 0 0 / 0%),
        rgba(0, 0, 0, 0.1) 20%,
        rgba(0, 0, 0, 0) 30%
      ),
      linear-gradient(#10939f05 55px, transparent 0);
  }

  .side-drawer {
    background: white;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  }

  .backdrop {
    background-color: #424242;
  }

  .notification-item {
    border: 1px solid #424242;
    background-color: #fff;
  }

  // rdt_Table rdt_TableRow rdt_TableCol rdt_TableCol_Sortable rdt_TableCell rdt_TableHeader rdt_TableFooter rdt_TableHead rdt_TableHeadRow rdt_TableBody rdt_ExpanderRow
}

.tab-scroll::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background: #424242;
  /* make scrollbar transparent */
}

div.status-polygon {
  display: flex;
  width: auto;
  height: 18px;
  padding-right: 5px;
  padding-left: 5px;
  background: #eef0fa;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  // outline: 1px solid #273EC3;
  box-sizing: content-box;
  border-bottom: 1px solid #273ec3;
  color: 1px solid #273ec3;
  border-top: 1px solid #273ec3;
}

.MuiAvatar-circular.MuiAvatar-colorDefault {
  font-size: 19px;
}

.customScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0px;
  background-color: transparent;
}

.customScroll::-webkit-scrollbar {
  width: 0px;
  height: 4px;
  background-color: transparent;
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ddd;
}

.iconActive {
  svg * {
    stroke: #45b755;
  }
}

.dropIconActive {
  svg * {
    stroke: #45b755 !important;
  }
}

span.iconBox.iconBox-active {
  color: #45b755;
  border: 1px solid #45b755;
  transition: all 0.2s ease;
}

span.iconBox {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all 0.2s ease;
  color: #fff;
  flex: 0 0 20px;
  font-size: 12px;
}

span.iconBox.iconBox_bg-active {
  color: #fff;
  border: 1px solid #fff;
}

.darkIcon {
  svg * {
    stroke: #222;
  }
}

.iconSml svg {
  width: 20px;
  height: 20px;
}
.textfield {
  border: 1px solid #d6d8dc;
  border-radius: 6px;
  min-height: 36px;
  max-height: 36px;
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border: 1px solid #45b755 !important;
    transition: all 0.2s ease;
  }
}

.textfield::placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.react-datepicker-popper {
  z-index: 1300 !important;
}

.iconBlue {
  svg * {
    stroke: #336cfc;
  }
}

.iconWhite {
  svg * {
    stroke: #fff;
  }
}
.iconBlack {
  svg * {
    stroke: #222;
  }
}

.iconGrey {
  svg * {
    stroke: #999696;
  }
}

.iconPrimary {
  svg * {
    stroke: #45b755;
  }
}

.iconSize-base {
  svg {
    width: 16px;
  }
}
.noBorder {
  border: none;
}
.labels {
  @apply font-semibold text-secondary dark:text-white mb-1.5 block;
  line-height: 16px;
  font-size: 13px !important;
  // text-transform: capitalize !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.qbs-table-qbs-table-menu-dropdown-content {
  right: 10px !important;
  padding-left: 12px !important;
  min-width: auto !important;
}

.formDrawer {
  width: 400px;
}

.customDrawer > .MuiDrawer-paper {
  right: 8px;
  top: 9px;
  height: calc(100% - 16px);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0px;
  background-color: rgba(45, 45, 45, 0.05);
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(45, 45, 45, 0.5);
}

.menuIconWidth svg {
  width: 18px;
  height: 18px;
}

.tileIconWidth svg {
  width: 18px;
  height: 18px;
}

.tileIconWidth-xs svg {
  width: 16px !important;
  height: 16px !important;
}

.qbs-table-toolbar-sub-container-start {
  gap: 12px !important;
  .selected-row {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }
  .selected-row-action {
    gap: 8px !important;
    .btn {
      padding: 4px 8px;
      height: 24px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      border-radius: 4px;
      border: 1px solid #45b755;
      background: #fff;
    }
  }
}

button.btn.relative.btn-secondary {
  border: 1px solid #999696 !important;
  div {
    color: #222 !important;
  }
}

.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-checkbox .custom-checkbox-input {
  opacity: 0;
}
.custom-checkbox .custom-checkbox-input ~ label {
  position: absolute;
  border-radius: 3px;
  border: 1px solid #999696;
  width: 16px;
  height: 16px;
  top: 3px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.custom-checkbox .custom-checkbox-input ~ label svg {
  opacity: 0;
  transition: all 0.2s ease;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 9px;
  height: 9px;
}
.custom-checkbox .custom-checkbox-input:checked ~ label {
  transition: all 0.2s ease;
  border-radius: 3px;
  background: #45b755;
  border-color: #45b755;
}
.custom-checkbox .custom-checkbox-input:checked ~ label svg {
  opacity: 1;
  transition: all 0.2s ease;
}
.duplicate-dropdown-list {
  position: absolute;
  width: 100%;
  min-height: auto;
  max-height: 250px;
  overflow-y: auto;
  padding: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  z-index: 999;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.duplicate-dropdown-list li {
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 6px;
}
.duplicate-dropdown-list li > div:first-child span {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.duplicate-dropdown-list li:last-child {
  border-bottom: none;
}

.duplicate-dropdown-list li:hover {
  background-color: #f0f0f0;
}

.duplicate-dropdown-list li:first-child {
  font-weight: 600;
}

.duplicate-dropdown-list li .fieldNameStyle {
  color: #e97575 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.iconWidthSm svg {
  width: 16px !important;
  height: 16px !important;
}
.qbs-table-custom-pagination .rows-count {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #313131;
}
.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox input {
  opacity: 0;
}

.custom-checkbox input ~ label {
  position: absolute;
  border-radius: 3px;
  border: 1px solid #999696;
  width: 16px;
  height: 16px;
  top: 3px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.custom-checkbox input ~ label svg {
  opacity: 0;
  transition: all 0.2s ease;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 8px;
  height: 8px;
}

.custom-checkbox input:checked ~ label {
  transition: all 0.2s ease;
  border-radius: 3px;
  background: #45b755;
  border-color: #45b755;
}
.custom-checkbox input:checked ~ label svg {
  opacity: 1;
  transition: all 0.2s ease;
}

.custom-checkbox-container {
  padding: 6px 8px;
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.custom-checkbox-container .custom-checkbox-value {
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.custom-checkbox-container .custom-checkbox {
  top: -5px;
}

.managedList-tabs {
  padding: 4px;
  border-radius: 6px;
  background: #edeff1;
}

.managedList-tabs button.MuiButtonBase-root.MuiTab-root {
  padding: 4px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  height: 24px;
  min-height: 24px;
  min-width: auto !important;
  color: #585858;
  border-radius: 4px;
  font-family: 'Nunito Sans', sans-serif !important;
}

.managedList-tabs > .MuiTabs-root {
  min-height: auto;
}

.managedList-tabs span.MuiTabs-indicator {
  background: transparent;
}

.managedList-tabs button.MuiButtonBase-root.MuiTab-root.Mui-selected {
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}

.managedList-tabs
  button.MuiButtonBase-root.MuiTab-root
  span.MuiTouchRipple-root {
  display: none;
}

.customMenu ul.MuiMenu-list {
  padding: 8px;
}

.customMenu .MuiPopover-paper.MuiMenu-paper {
  margin-top: 18px;
  min-width: 180px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.customMenu ul.MuiMenu-list li {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px;
  border-radius: 6px;
}

.iconWidthXs svg {
  width: 15px !important;
  height: 15px !important;
}

.customAccordion {
  box-shadow: none !important;
  background: #fff;
  border-radius: 0 !important;
  margin: 0 !important;
}
.customAccordion .accordionHeader {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 4px;
  min-height: auto !important;
  padding: 0;
}

.customAccordion .accordionHeader .MuiAccordionSummary-content,
.customAccordion .accordionHeader .MuiAccordionSummary-content p {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0 !important;
  font-family: 'Nunito Sans', sans-serif;
}
.customAccordion .accordionDetails.accordionHidden {
  overflow: hidden;
  max-height: 0;
  transition: all 0.35s ease-out;
  padding: 0 20px;
}
.customAccordion .accordionDetails {
  padding: 0 20px 16px;
  max-height: 400px;
  transition: all 0.35s ease-out;
  overflow-y: auto;
}
.customAccordion .accordionDetails.general {
  padding: 0 20px 16px;
  max-height: 400px;
  transition: all 0.35s ease-out;
  overflow-y: auto;
}

.customFieldTable {
  border: 1px solid #d6d8dc;
  border-radius: 4px;
  background: #fff;
}

.customFieldTable > .customFieldTable-container {
  box-shadow: none;
  border: none;
}

.customFieldTable table thead tr th {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 10px 8px;
  background: #f2f2f2;
}

.customFieldTable table tbody tr th,
.customFieldTable table tbody tr td {
  padding: 8px;
  line-height: normal !important;
  background: #f8f8f8;
  vertical-align: baseline;
}

.customFieldTable table tbody tr th .textfield,
.customFieldTable table tbody tr td .textfield,
.customFieldTable table tbody tr td .textfield-error,
.customFieldTable table tbody tr th .textfield-error {
  min-height: 24px;
  max-height: 24px;
  padding: 0 8px !important;
}

.customFieldTable table tbody tr th .qbs-autocomplete-close-icon {
  top: 5px;
}

.customFieldTable table tbody tr td.customFieldTable-cell,
.customFieldTable table tbody tr td.actionCell {
  background: #fff;
  vertical-align: middle;
}
.iconRotate svg {
  transform: rotate(180deg);
  transition: all 0.2s ease;
}
.customAccordion .accordionHeader span.flex-1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #313131;
}

.customFieldTable table tbody tr td .textfield {
  text-align: right;
}

.customFileUpload .customFileUpload-field > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: -3;
}
.menuopened {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 4px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 220px;
  z-index: 9;
}

.menuopened .menuopened-item {
  padding: 6px 8px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #222;
}
.menuopened .menuopened-item:hover {
  background: #f8f8f8;
}

.customRadioButton .customRadio-field > div {
  position: relative;
}

.customRadioButton .customRadio-field input {
  opacity: 0;
  position: absolute;
}

.customRadioButton .customRadio-field input ~ label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #222;
  padding-left: 22px;
}

.customRadioButton .customRadio-field input ~ label:before {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  border-radius: 100px;
  border: 1px solid #d6d8dc;
  background: #fff;
  top: 2px;
  transition: all 0.2s ease;
}

.customRadioButton .customRadio-field input:checked ~ label:before {
  border: 1px solid #45b755;
  transition: all 0.2s ease;
}

.customRadioButton .customRadio-field input ~ label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 5px;
  width: 10px;
  height: 10px;
  background: #45b755;
  border-radius: 100px;
  opacity: 0;
  transition: all 0.2s ease;
}

.customRadioButton .customRadio-field input:checked ~ label:after {
  opacity: 1;
  transition: all 0.2s ease;
}
textarea.textfield,
textarea.textfield-error {
  height: auto !important;
  min-height: initial !important;
  max-height: initial !important;
  border-radius: 6px;
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.2s ease;
}
textarea.textfield-error::placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.commentFormField {
  position: relative;
}

.commentFormField > button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

ul.notesList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

ul.notesList ul.commentList {
  margin-top: 20px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

ul.notesList ul.commentList ul.commentList {
  margin-top: 16px;
  gap: 12px;
}

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px 0 0;
  padding: 10px 10px 10px 10px;
  min-width: 180px;
  height: 40px;
  float: left;
  position: relative;
  background-color: #f2f2f2;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  flex: 1;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: ' ';
  content: ' ';
  position: absolute;
  top: 0;
  right: -18px;
  width: 0;
  height: 40px;
  border-top: 19px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 19px solid #f2f2f2;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0px;
  border-left: 20px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: '✔';
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #299957;
  background-color: #d4ebdd;
}

.arrow-steps .step.lost {
  color: #b6514c;
  background-color: #cf8787;
}
.arrow-steps .step.lost:after {
  border-left: 19px solid #cf8787;
}
.arrow-steps .step.current:after {
  border-left: 19px solid #d4ebdd;
}
.arrow-steps {
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.arrow-steps .step:last-child:after {
  border: none !important;
}
.menuopened.styledMenu {
  min-width: 100%;
  margin-top: 20px;
}
.qbs-autocomplete-suggestions {
  z-index: 999 !important;
}
.qbs-table .qbs-table-search-container > form {
  position: relative;
}
.action_label {
  cursor: pointer;
  color: #4c7ad6;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 0.375rem;
  position: relative;
}
// .action_label:before {
//   content: " + ";
//   position: absolute;
//   left: -16px;
//   top: -1px;
//   color: #4c7ad6;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 16px;
// }
.autoWidth > div {
  margin-bottom: 0 !important;
}

.qbs-table .qbs-table-search-container .input {
  padding-left: 30px !important;
  padding-right: 32px !important;
}
.qbs-table .qbs-table-search-container .close-button {
  right: 12px !important;
}

.textfield:disabled,
.textfield-big:disabled {
  border-radius: 4px;
  border: #d6d8dc;
  background: #f2f2f2;
  color: #681212;
}

.qbs-table .qbs-table-toolbar-container {
  gap: unset !important;
}

.qbs-table .qbs-table-toolbar-container .qbs-table-toolbar {
  margin-bottom: 24px !important;
}
.rs-table-cell-content {
  padding-left: 13px !important;
}

.qbs-table
  .qbs-table-toolbar-container
  .sub-qbs-table-toolbar
  .flex.items-center.flex-wrap
  > div {
  margin-bottom: 0px !important;
}
.qbs-table
  .qbs-table-toolbar-container
  .sub-qbs-table-toolbar
  .flex.items-center.flex-wrap
  > div.filter {
  margin: 0px 0 12px !important;
}
.qbs-table
  .qbs-table-toolbar-container
  .sub-qbs-table-toolbar
  .flex.items-center.flex-wrap.filter-container {
  margin-bottom: 0px !important;
}

// .customDrawer
//   .formDrawer
//   .flex.flex-col
//   > div:nth-last-child(2)
//   .qbs-autocomplete-suggestions {
//   bottom: 100%;
// }
// .customDrawer
//   .formDrawer
//   .flex.flex-col
//   > div:nth-last-child(3)
//   .qbs-autocomplete-suggestions {
//   bottom: 100%;
// }

// .customDrawer
//   .formDrawer
//   .flex.flex-col
//   > div:last-child
//   .qbs-autocomplete-suggestions {
//   bottom: 100%;
// }

// .customFieldTable tbody tr:last-child th .qbs-autocomplete-suggestions {
//   bottom: 100%;
// }
// .customFieldTable tbody tr:nth-last-child(2) th .qbs-autocomplete-suggestions {
//   bottom: 100%;
// }
// .customFieldTable tbody tr:nth-last-child(3) th .qbs-autocomplete-suggestions {
//   bottom: 100%;
// }

// .customFieldTable tbody tr:nth-last-child(4) th .qbs-autocomplete-suggestions {
//   bottom: 100%;
// }
// .customFieldTable tbody tr:first-child th .qbs-autocomplete-suggestions {
//   bottom: unset !important;
// }
// .customFieldTable tbody tr:nth-child(2) th .qbs-autocomplete-suggestions,
// .customFieldTable tbody tr:nth-child(3) th .qbs-autocomplete-suggestions,
// .customFieldTable tbody tr:nth-child(4) th .qbs-autocomplete-suggestions {
//   bottom: unset !important;
// }
.qbs-table-row-link {
  color: var(--text-color-link, #336cfc);
}
.qbs-autocomplete-close-icon {
  background: transparent !important;
}
.customDrawer {
  z-index: 1300 !important;
}
.qbs-table-tooltip {
  position: relative;
  display: contents;
  cursor: pointer;
}

.qbs-table-tooltip .tooltiptext {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 6px;
  border-radius: 4px;
  position: absolute;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  width: 100px;
}
.dash-table .rs-table-cell-group-fixed-right {
  display: none !important;
}
.dash-table .qbs-table .qbs-table-border-wrap {
  border: none !important;
}
.rs-table-cell-header .rs-table-cell-content {
  font-size: 15px !important;
  font-weight: bold !important;
}
.Capitalism {
  text-transform: capitalize !important;
}
.qbs-table-tooltip.up .tooltiptext {
  bottom: 125%;
  right: 0px;
  left: auto;
}

.qbs-table-tooltip.down .tooltiptext {
  top: 125%;
  right: 0px;
  left: auto;
}

.qbs-table-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.qbs-table-tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.qbs-table-tooltip.up .tooltiptext::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #000 transparent transparent transparent;
}

.qbs-table-tooltip.down .tooltiptext::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #000 transparent;
}
.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}

.template_Block .template_content {
  width: 100%;
  background: #fff;
  padding: 0;
}

span.statusLabel.Active {
  border-radius: 6px;
  border: 1px solid #45b755;
  background: rgba(198, 58, 58, 0.08);
  color: #c63a3a;
  padding: 2px 6px;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  margin-left: 6px;
}

span.statusLabel.Inactive {
  border-radius: 6px;
  border: 1px solid #d6d8dc;
  background: rgba(51, 51, 51, 0.08);
  color: #313131;
  padding: 2px 6px;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  margin-left: 6px;
}

.customScroll {
  scrollbar-width: none;
}

input.textfield[data-testid='custom-autocomplete'] {
  padding-right: 40px;
}

.row-status-tooltip.bottom-position {
  min-width: 300px;
  white-space: break-spaces;
  text-align: left;
}

form .qbs-autocomplete-close-icon .icon-button {
  display: none !important;
}

@media print {
  body * {
    visibility: hidden;
  }
  .printable-content,
  .printable-content * {
    visibility: visible;
  }
  .printable-content {
    /* Your print styles here */
    width: 100%;
    height: 100%;
    overflow: visible; /* Ensure no scrollbars */
  }
}
