.dropdown {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-toggle {
  // padding: 8px 2px;
  font-size: 12px;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: block;
  z-index: 1;
  // background-color: #f1f1f1;
  // border: 1px solid #ccc;
  border-top: none;
  min-width: 160px;
  padding: 0;
  margin: 0;
}

.dropdown-menu a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #333;
}
